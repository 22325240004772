body {
  font-family: "Poppins", sans-serif;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.main-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #1f1f1e;
  border-radius: 8px;
  color: #fff;
}

.grey-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  color: #000;
}

.orange-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ff7268;
  color: #fff;
}

.true-orange-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FD661F;
  color: #fff;
}

.bodyGrey {
  background: #e5e5e5;
}

.bodyBluish {
  background: #002979;
}

button.footer-request {
  border: 2px solid #fff;
  color: #fff;
}

/* nav {
  box-shadow: 0px 8px 30px rgb(9 44 76 / 16%);
} */
.guest-nav li a:hover {
  /* font-weight: 600; */
  border-bottom: 3px solid;
  border-image: linear-gradient(
      to right,
      #fd661f 25px,
      #ffff0000 25%,
      #ffffff00 50%,
      #ffffff00 50%,
      #ffffff00 75%,
      #ffffff00 75%
    )
    5 !important;
}
.guest-nav li a.active {
  /* font-weight: 600; */
  border-bottom: 3px solid;
  border-image: linear-gradient(
      to right,
      #fd661f 25px,
      #ffff0000 25%,
      #ffffff00 50%,
      #ffffff00 50%,
      #ffffff00 75%,
      #ffffff00 75%
    )
    5 !important;
}
.guest-nav li a {
  color: #000;
  font-weight: 400;
  border-bottom: 3px solid transparent !important;
}
.main-logo {
  width: 50%;
}
.text-grey {
  color: #c3c3c3;
}
.contact-box {
  background: #0d69a8;
}

/* ACCORDION CSS START */
.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
}
.duration-500 {
  transition-duration: 0.5s;
}
.outline-none {
  outline: 0;
}
.group:focus .group-focus\:max-h-screen {
  max-height: 100vh;
}
.group:focus .group-focus {
  --text-opacity: 1;
  color: #04124c;
  color: rgba(255, 255, 255, var(--text-opacity));
}
.group:focus {
  background: #04124c1a;
}
.group:focus .group-focus\:-rotate-180 {
  --transform-rotate: -180deg;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
/* ACCORDION CSS END */
#about-instructors #about-card {
  background: #FFF;
}

#about-instructors #about-card i {
  color: #FD661F;
}

#about-instructors #about-card h3, #about-instructors #about-card span {
  color: #000;
}

#about-instructors #about-card:hover {
  background: #FD661F;
}

#about-instructors #about-card:hover i {
  color: #FFF;
}

#about-instructors #about-card:hover h3, #about-instructors #about-card:hover span {
  color: #FFF;
}

.pointer {
  cursor: pointer;
}

.auth-orange-button {
  background: #F48023;
  color: #FFFFFF;
  border-radius: 5px;
}

.bg-theme-grey {
  background: #E8EFF6;
  /* height: 100vh; */
}

.w-80 {
  width: 80%;
}

.card-grey-text {
  color: #808080;
}

.card-footer-text {
  background-color: #F8F8F8;
  color: #000000;
}

.course-card-tagline {
  max-width: 10rem;
}

.course-card-instructor-image {
  width: 60px;
  height: 60px;
  border: 4px solid #FFFFFF;
}

.bg-danger {
  background: #DA3743;
  border-radius: 5px;
}


/* AUTH NAVBAR */
.authNavbar nav.sidenav {
  box-shadow: 0px 8px 30px rgb(9 44 76 / 16%);
}
.authNavbar .side-nav {
  background: #002979;
  color: #FFFFFF;
}
.authNavbar li a {
  color: #808080;
}
.authNavbar li:hover {
  background: #2060400D;
  color: #FFFFFF;
}
.authNavbar li a span {
  color: #FFFFFF;
}  
.authNavbar li.active a span {
  color: #FFFFFF;
}
.authNavbar li.active, .authNavbar li:hover {
  background: linear-gradient(90deg, #1C4185 99%, rgba(28, 65, 133, 0) 100%);
}

.authNavbar li.menu-dropdown {
  background-color: #fff !important;
}

@media screen and (max-width: 1200px) {
  .authNavbar .border-radius-sm {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
  }   
}


/* since nested groupes are not supported we have to use 
  regular css for the nested dropdowns 
*/
.authNavbar li>ul                 { transform: translatex(100%) scale(0) }
.authNavbar li:hover>ul           { transform: translatex(101%) scale(1) }
.authNavbar li > button svg       { transform: rotate(-90deg) }
.authNavbar li:hover > button svg { transform: rotate(-270deg) }

/* Below styles fake what can be achieved with the tailwind config
  you need to add the group-hover variant to scale and define your custom
  min width style.
  See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  for implementation with config file
*/
.group:hover .group-hover\:scale-100 { transform: scale(1) }
.group:hover .group-hover\:-rotate-180 { transform: rotate(180deg) }
.scale-0 { transform: scale(0) }
.min-w-32 { min-width: 8rem }
/* AUTH NAVBAR */

.pointer {
  cursor: pointer !important;
}

ul.mobile-menu li.active {
  background: #0001030a;
}

.c-item div {
  text-align: center;
}